import React from 'react';

import i18next from '../../config/i18next';
import { getSSOHostname } from '../../config/ssoUtil';
import { isChinaUser } from '../../config/isChina';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = { callbackURLConnect: '' };
        this.isWidgetLoaded = false;
    }

    componentDidMount() {
        // Check for `service` parameter with the callbackURL target
        const service = this.queryString('service')[0];
        this.setState({ callbackURLConnect: service || `${window.location.origin}/modern/` });
    }

    render() {
        return (
            <div id="loginGauth" style={{ position: 'absolute' }}>
                {this.state.callbackURLConnect ? this.renderLogin() : ''}
            </div>
        );
    }

    /*
     * renderLogin - render the SSO widget once everything is loaded
     */
    renderLogin() {
        const { callbackURLConnect } = this.state;
        const environment = getSSOHostname(callbackURLConnect);

        if (!this.isWidgetLoaded) {
            const cssUrl = isChinaUser()
                ? window.location.origin + '/gauth-custom-cn-v1.2-min.css'
                : window.location.origin + '/gauth-custom-v1.2-min.css';
            const scriptWidget = document.createElement('script');

            scriptWidget.src = `https://${environment}/sso/js/gauth-widget.js`;
            scriptWidget.id = 'jQueryId';
            // Load Gauth rendering code now that widget is loaded
            scriptWidget.onload = function() {
                const scriptGauth = document.createElement('script');
                scriptGauth.text = `
                        GAUTH.init({
                                "gauthHost":                       "https://${environment}/sso",
                                "callBackWebHostUrl":              "${callbackURLConnect}",
                                "clientId":                        "GarminConnect",
                                "locale":                          "${i18next.language}",
                                "id":                              "gauth-widget",
                                "rememberMeShown":                 true,
                                "rememberMeChecked":               false,
                                "customerId":                      "",
                                "createAccountShown":              true,
                                "displayNameShown":                false,
                                "usernameShown":                   false,
                                "connectLegalTerms":               true,
                                "globalOptInShown":                true,
                                "consumeServiceTicket":            false,
                                "locationPromptShown":             true,
                                "generateExtraServiceTicket":      true,
                                "generateTwoExtraServiceTickets":  true,
                                "privacyStatementUrl":             "https://www.garmin.com/${
                                    i18next.language
                                }/privacy/connect/",
                                "cssUrl":                          "${cssUrl}",
                                "redirectAfterAccountCreationUrl": "${callbackURLConnect}",
                                "redirectAfterAccountLoginUrl":    "${callbackURLConnect}"
                        });
                        GAUTH.loadGAuth();
                        if (window.location.hash == '#create') {GAUTH.openCreateAccount();}                        
                    `;

                scriptGauth.id = 'gauthId';
                document.getElementById('loginGauth').appendChild(scriptGauth);
            };
            document.getElementById('loginGauth').appendChild(scriptWidget);
            this.isWidgetLoaded = true;
        }
    }

    // Once we drop support for IE11 this can be removed and switch to https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
    queryString(obj) {
        const result = [];
        let match;
        const regex = new RegExp('(?:\\?|&)' + obj + '=(.*?)(?=&|$)', 'gi');
        while ((match = regex.exec(document.location.search)) !== null) {
            result.push(match[1]);
        }
        return result;
    }
}

export default Login;
