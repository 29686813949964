import React from 'react';
import injectSheet from 'react-jss';
import Helmet from 'react-helmet';

import Layout from '../components/layout';
import Login from '../components/login/login';
import i18next from '../config/i18next';

const styles = {
    gauthContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    gauthWidget: {
        borderRadius: '0px',
        backgroundColor: '#fafafa',
        width: '350px',
        justifyContent: 'center',
        display: 'flex'
    },
    signin: {
        height: '80%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    }
};

class Signin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            mobileImages: this.importAll(require.context('../data/images/login/mobile', false, /\.(png|jpe?g)$/)), // Creates an object of filenames and paths that can be accessed programmatically
            tabletImages: this.importAll(require.context('../data/images/login/tablet', false, /\.(png|jpe?g)$/)),
            desktopImages: this.importAll(require.context('../data/images/login/desktop', false, /\.(png|jpe?g)$/))
        };
    }

    componentDidMount() {
        this.loadTitleTranslation();
    }

    /*
     * The purpose of this function is to ensure that translations are loaded before we set the title in the head. React Helmet does not allow React
     * nodes for the title so we cannot use Translate component. Using i18next directly would just load the key as i18next hasn't loaded the the json files yet.
     * So we need to keep track of the title state and update it once i18next loads translations
     */
    loadTitleTranslation() {
        i18next.loadNamespaces('prelogin_pages', (err) => {
            if (err) {
                console.log(err[0]);
            }

            let text = i18next.t(`prelogin_pages:sign_in`, { skipInterpolation: true });
            this.setState({ title: text });
        });
    }

    render() {
        const { classes } = this.props;
        const title = this.state.title;

        // This prevents the background images from being loaded more than once since render() is called multiple times
        if (!this.bgImages) {
            this.setBgImages();
        }

        return (
            <Layout
                responsiveBackgroundUrl={this.bgImages.responsiveBackgroundImage}
                tabletBackgroundUrl={this.bgImages.tabletBackgroundImage}
                backgroundUrl={this.bgImages.backgroundImage}
            >
                <div className={classes.signin}>
                    <Helmet>
                        <title>Garmin Connect | {title}</title>
                        <meta
                            name="description"
                            content="Sign in to Garmin Connect to track, analyze and share the activities from your Garmin device."
                        />
                        <meta property="og:title" content="Garmin Connect | Sign In" />
                        <meta property="og:url" content={`https://connect.garmin.com/signin`} />
                        <meta
                            property="og:description"
                            content="Sign in to Garmin Connect to track, analyze and share the activities from your Garmin device."
                        />
                        <meta
                            property="og:image"
                            content="http://static.garmincdn.com/com.garmin.connect/content/images/garmin-connect-fb3.gif"
                        />
                        <meta name="twitter:title" content="Garmin Connect | Sign In" />
                    </Helmet>
                    <div className={classes.gauthContainer}>
                        <div className={classes.gauthWidget} id="gauth-widget" />
                    </div>
                    <Login />
                </div>
                <noscript>To sign in to your Garmin account, please enable JavaScript in your web browser.</noscript>
            </Layout>
        );
    }

    // Import all of the background images from the given require request object
    importAll(requireRequest) {
        let imagesFromDirectory = {};

        requireRequest.keys().forEach((item, index) => {
            imagesFromDirectory[item.replace('./', '')] = requireRequest(item);
        });

        return imagesFromDirectory;
    }

    // Creates random background image URLs
    setBgImages() {
        const numberOfMobileImages = Object.keys(this.state.mobileImages).length;
        const numberOfTabletImages = Object.keys(this.state.tabletImages).length;
        const numberOfDesktopImages = Object.keys(this.state.desktopImages).length;

        const randomNumberForMobile = Math.floor(Math.random() * numberOfMobileImages);
        const randomNumberForTablet = Math.floor(Math.random() * numberOfTabletImages);
        const randomNumberForDesktop = Math.floor(Math.random() * numberOfDesktopImages);

        const randomMobileImageKey = Object.keys(this.state.mobileImages)[randomNumberForMobile];
        const randomTabletImageKey = Object.keys(this.state.tabletImages)[randomNumberForTablet];
        const randomDesktopImageKey = Object.keys(this.state.desktopImages)[randomNumberForDesktop];

        this.bgImages = {
            responsiveBackgroundImage: this.state.mobileImages[randomMobileImageKey],
            tabletBackgroundImage: this.state.tabletImages[randomTabletImageKey],
            backgroundImage: this.state.desktopImages[randomDesktopImageKey]
        };
    }
}

export default injectSheet(styles)(Signin);
